var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"onboarding__container gradient",staticStyle:{"justify-content":"center","height":"90vh"}},[_c('div',[_c('h4',{staticClass:"smalltitle",staticStyle:{"text-align":"center"}},[_vm._v(" Hi "+_vm._s(_vm.current_user.username)+" ")]),_c('p',{staticStyle:{"text-align":"center","font-size":"1.5em","font-family":"poppins","font-weight":"400","style":"normal fo"}},[_vm._v(" Let's get Carl added to your server ")]),_vm._m(0)]),_c('button',{staticClass:"saucy-btn",staticStyle:{"border-radius":"48px"},on:{"click":_vm.startOnboarding}},[_vm._v(" Get started ")]),_c('p',[_vm._v("PS: All of the settings can be changed after you're done")]),_c('button',{staticClass:"skip-btn",on:{"click":_vm.skipOnboarding}},[_vm._v("SKIP")]),_vm._l((_vm.turtle_coordinates),function(coord){return _c('img',{key:coord,style:({
      position: 'absolute',
      left: ((coord[0]) + "%"),
      right: ((coord[1]) + "%"),
      top: ((coord[2]) + "%"),
      bottom: ((coord[3]) + "%"),
      opacity: 0.3,
    }),attrs:{"src":require("@/../public/img/brand/newcarl.svg"),"alt":""}})})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("@/../public/img/brand/landing_art.svg"),"alt":""}})])}]

export { render, staticRenderFns }